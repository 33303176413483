<template>
  <div>

    <div class="fade text-start modal-primary"
         id="primary"
         tabindex="-1"
         style="display: block; background: rgba(28, 29, 28, 0.2)"
         :class="{'modal': show, 'show': show}"
         v-show="show"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="overflow-hidden" style="max-height: 70vh">
              <img :src="src" ref="image" style="display: block; max-width: 100%;">

            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary waves-effect waves-float waves-light" @click=" cropper.destroy();$emit('onImageCropped', croppedImage);">
              Crop
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Cropper from 'cropperjs'
import {debounce} from "lodash";

export default {
  name: "ImageCropper",
  emits: ['onImageCropped'],
  props: {
    show: {
      default: false,
      type: Boolean
    },
    img:{
      default: '',
    },
    type:{
      default: '',
    },
    name:{
      default: '',
    },
    aspectRatio:{
      default: '1',
      type: Number
    }
  },
  data: () => ({
    src: '',
    processedImage: '',
    timeout: null,
    croppedImage: '',
    cropper: '',
  }),
  watch:{
    img(){
    },
    show(val){
      if(val){
        this.timeout = ''
        this.src = this.img
        this.processedImage = this.$refs.image
        clearTimeout(this.timeout)
        this.init()
      }

    }
  },
  methods:{
    init: debounce(function(){
      let vm = this

      vm.cropper = new Cropper(vm.processedImage, {
        aspectRatio: vm.aspectRatio,
        scalable: false,
        zoomable: true,
        crop: (event) => {
          if(vm.cropper.cropped){
            const canvas = vm.cropper.getCroppedCanvas()
            vm.croppedImage = canvas.toDataURL(this.type)
          }

        }
      });
    },200)


  },
  mounted() {
  }
}
</script>

<style scoped>
</style>